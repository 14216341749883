import React, { useState, useEffect } from "react";
import { Button,ButtonGroup, Modal, Form, Row, Col, ModalFooter, Badge, ModalBody } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getUser } from "../../utils/Common";
import Header from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; 
import "./style.css";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";

import tennis from '../../assets/tennis.png';
import table from '../../assets/table-tennis.png';


const Schedule = () => {
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [hotel, setHotelID] = useState("");
  const [facList, setFacList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalDetailDate, setShowModalDetailDate] = useState(false);
  const [detailDate, setDetailDate] = useState([]);
  
  const [schList, setSchList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [guestInHouse, setGuestInHouse] = useState([]);
  const [showParticipantModal, setShowParticipantModal] = useState(false);

  const [scheduleData, setScheduleData] = useState({
    title: "",
    facility: "",
    imgfac: "",
    dateFrom: "",
    dateTo: "",
    timeSlots: [{ timeFrom: "", timeTo: "" }],
  });

  const [editTime, setEditTime] = useState([]);
  const [editingSchedule, setEditingSchedule] = useState(null); // ID of the currently edited schedule
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleEditClick = (schedule) => {
    setEditingSchedule(schedule.id_sch);
    setStartTime(schedule.schedule_start);
    setEndTime(schedule.schedule_end);
  };

  const handleSave = async (id) => {
    try {
      // Send updated data to the server
      const response = await axios.post(`/api/sch/update/time/${id}/${hotel}`, {
        schedule_start: startTime,
        schedule_end: endTime,
      });

      console.log("Update response:", response.data);

      // Update the local state with the new times
      setDetailDate((prevDetails) =>
        prevDetails.map((item) =>
          item.id_sch === id
            ? { ...item, schedule_start: startTime, schedule_end: endTime }
            : item
        )
      );

      setEditingSchedule(null); // Close the editing form
    } catch (error) {
      console.error("Error updating schedule:", error);
    }
  };

  // State for participant form fields
  const [participantData, setParticipantData] = useState({
    idSch:"",
    guestName: "",
    roomNo: "",
    roomName: "",
    remark: "",
    pax: 1,
    arr: "",
    dep: ""
  });

  // Function to handle adding a new schedule (open modal)
  const handleAddNewSchedule = () => {
    setShowModal(true);
  };

  // Function to handle modal close for new schedule
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Function to handle saving a new schedule
  const handleSaveSchedule = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log(scheduleData);
      const response = await axios.post(`/api/sch/add/${hotel}`, scheduleData);
      console.log(response.data);

      if (response.data.status === 200) {
        Swal.fire("Success", response.data.message, "success");
        setShowModal(false);
        setScheduleData({
          title: "",
          facility: "",
          dateFrom: "",
          // typeSchedule: "",
          dateTo: "",
          timeSlots: [{ timeFrom: "", timeTo: "" }],
        });

        // Fetch updated schedule list
        const scheduleResponse = await axios.get(`/api/sch/calendar/${hotel}`);
        const formattedSchedules = scheduleResponse.data.map((schedule) => ({
          id: schedule.id,
          title: schedule.title,
          fac: schedule.fac,
          imgfac: schedule.imgfac,
          date: schedule.date,
          status: schedule.status,
          stime: schedule.stime,
          etime: schedule.etime,
          guestBooking: schedule.participant,
          nophone: schedule.nophone,
          pax: schedule.pax,
          remark: schedule.remark,
          bDate: schedule.bDate,
          bTime: schedule.bTime,
        }));
        setSchList(formattedSchedules);
      } else {
        Swal.fire("Info", response.data.message, "info");
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      Swal.fire("Error", "Failed to add facility", "error");
    } finally {
      setLoading(false);
    }
  };

  // Funcion to delete time schedyle
  
  const handleDelete = async (id,dateIs) => {
    try {
      // Send delete data to the server
      const response = await axios.get(`/api/sch/delete/time/${id}/${hotel}`);
      console.log("delete response:", response.data);
      handleDateSelect(dateIs)
     
    } catch (error) {
      console.error("Error updating schedule:", error);
    }
  };

  // Function to add more time slots
  const handleAddMoreTime = () => {
    setScheduleData({
      ...scheduleData,
      timeSlots: [...scheduleData.timeSlots, { timeFrom: "", timeTo: "" }],
    });
  };

  // Function to handle change in time slots
  const handleTimeSlotChange = (index, field, value) => {
    const newTimeSlots = [...scheduleData.timeSlots];
    newTimeSlots[index][field] = value;
    setScheduleData({ ...scheduleData, timeSlots: newTimeSlots });
  };

  // Function to handle participant form submission (Insert)
  const handleSaveParticipant = async(e) => {
    e.preventDefault();

    const formData = {
      guestName: participantData.guestName,
      roomNo: participantData.roomNo,
      roomType: participantData.roomName,
      remark: participantData.remark,
      pax: participantData.pax,
      arri: participantData.arr,
      dept: participantData.dep,
      idSch: participantData.idSch,
    };

    try {
      const response = await axios.post(`/api/sch/update-booking/${hotel}`,formData);
      console.log("Participant Data (Insert):", response.data);
      // window.location.reload();
      if(response.data.status === 200){
        Swal.fire("Success", response.data.message, "success").then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      Swal.fire("Error", "Failed to get guest in house", "error");
    } finally {
      setLoading(false);
      handleCloseParticipantModal();
      Swal.fire("Success", "Participant has been saved", "success");
    }

  };

  const handleUpdateParticipant = async (e) => {
    e.preventDefault();
    console.log(participantData);
  
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the participant details?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = {
          idSch: participantData.idSch,
          guestName: participantData.guestName,
          roomNo: participantData.roomNo,
          roomType: participantData.roomName,
          remark: participantData.remark,
          pax: participantData.pax,
          arri: participantData.arr,
          dept: participantData.dep,
        };
  
        try {
          const response = await axios.post(`/api/sch/update-detail/${hotel}`, formData);
          console.log("Participant Data (Update):", response.data);
          if(response.data.status === 200){
            Swal.fire("Success", response.data.message, "success").then(() => {
              window.location.reload();
            });
          }
        } catch (error) {
          console.error("Error:", error.response?.data || error.message);
          Swal.fire("Error", "Failed to update participant details", "error");
        } finally {
          setLoading(false);
          handleCloseParticipantModal();
        }
      }
    });


  };

  // Function for "Release" button
  const handleReleaseParticipant = async (e) => {
  e.preventDefault();

  Swal.fire({
    title: "Are you sure?",
    text: "Do you want to release the participant?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, release it!"
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await axios.get(`/api/sch/release-participant/${participantData.idSch}`);
        console.log("Participant Data (Release):", response.data);
        if(response.data.status === 200){
          Swal.fire("Success", response.data.message, "success").then(() => {
            window.location.reload();
          });
        }
      } catch (error) {
        console.error("Error:", error.response?.data || error.message);
        Swal.fire("Error", "Failed to release participant", "error");
      } finally {
        setLoading(false);
        handleCloseParticipantModal();
      }
    }
  });
  };
  
  // Function to handle event click
  const handleEventClick = async (clickInfo) => {
    setSelectedEvent(clickInfo.event);
    console.log(clickInfo.event)
    // Pre-fill form fields based on extendedProps
    if (clickInfo.event.extendedProps) {
      setParticipantData({
        guestName: clickInfo.event.extendedProps.guestBooking || "",
        roomNo: "",
        roomName: "",
        remark: clickInfo.event.extendedProps.remark || "",
        pax: clickInfo.event.extendedProps.pax || 2,
        arr: "",
        dep: "",
        idSch: clickInfo.event.extendedProps.idSch,
      });
    }

    try {
      const response = await axios.get(`/api/guest/inhouse/${hotel}`);
      const filteredData = response.data.filter((item) => item.GuestName);
      const sortedData = filteredData.sort((a, b) => a.GuestName.localeCompare(b.GuestName));
      setGuestInHouse(sortedData);
      setShowParticipantModal(true);
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      Swal.fire("Error", "Failed to get guest in house", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleDateSelect = async(selectInfo) => {
    const clickedDate = selectInfo.startStr; // This gives you the selected date in "YYYY-MM-DD" format
    // console.log("Date selected:", clickedDate);
    // LoadData
    try {
      const response = await axios.get(`/api/sch/onstartdate/${clickedDate}/${hotel}`);
      console.log(response.data);
     
      setDetailDate(response.data)
      setShowModalDetailDate(true)
      // console.log("Facilities:", response.data);
    } catch (error) {
      console.error("Error fetching facilities:", error);
    }
  

  };

  // Function to handle participant modal close
  const handleCloseParticipantModal = () => {
    setShowParticipantModal(false);
    setSelectedEvent(null);
  };

  const [statusFilter, setStatusFilter] = useState("All"); // Status filter (All, Vacant, Booked, Release)
  const [facilityFilter, setFacilityFilter] = useState([]); // Array to store selected facilities
  const [filteredSchList, setFilteredSchList] = useState([]);
  
  // Handle Status Filter
  const handleStatusFilter = (status) => {
    setStatusFilter(status);
  };

  // Function to filter schedules based on status and facilities
  const handleFacilityFilter = () => {
    
    const result = schList.filter((schedule) => {
     

      // Status filter
      if (statusFilter !== "All" && schedule.status !== statusFilter) {
        console.log("Filtered out due to status:", schedule.status);
        return false;
      }

      // Facility filter
      if (facilityFilter.length > 0 && !facilityFilter.includes(schedule.idFac)) {
        console.log("Filtered out due to facility filter:", schedule.idFac);
        return false;
      }

      return true;
    });

    // Update state with filtered results
    setFilteredSchList(result);
  };

  // Fetch user and facilities data
  useEffect(() => {
    const fetchUserDataAndFacilities = async () => {
      const userData = getUser();
      if (userData) {
        setUserDetail(userData);
        const idHotel = userData.hotel_id;
        setHotelID(idHotel);

        try {
          const response = await axios.get(`/api/fac/hotel/${idHotel}`);
          setFacList(response.data);
          console.log("Facilities:", response.data);
        } catch (error) {
          console.error("Error fetching facilities:", error);
        }

        try {
          const scheduleResponse = await axios.get(`/api/sch/calendar/${idHotel}`);
          const formattedSchedules = scheduleResponse.data.map((schedule) => ({
            idSch: schedule.idSch,
            idFac: schedule.idFac,
            title: schedule.title,
            fac: schedule.fac,
            imgfac: schedule.imgfac,
            date: schedule.date,
            status: schedule.status,
            stime: schedule.stime,
            etime: schedule.etime,
            guestBooking: schedule.participant,
            nophone: schedule.nophone,
            pax: schedule.pax,
            remark: schedule.remark,
            bDate: schedule.bDate,
            bTime: schedule.bTime,
          }));
          setSchList(formattedSchedules);
        } catch (error) {
          console.error("Error fetching schedules:", error);
        }
      }
    };

    fetchUserDataAndFacilities();
  }, []);

  // Apply filters whenever schList, statusFilter, or facilityFilter changes
  useEffect(() => {
    handleFacilityFilter();
  }, [schList, statusFilter, facilityFilter]); // Re-run filter when these dependencies change


  return (
    <>
      <Header userDetail={userDetail} />
      <div className="container mt-5 mb-5">
        <div className="row mb-3">
          <div className="col-md-4">
          <ButtonGroup size="sm">
            <Button variant="secondary" style={{width:"80px",height:"30px",fontSize:"12px"}} onClick={() => window.location.reload()}> <i className="bi bi-arrow-clockwise"></i> All</Button>
            <Button variant="primary" style={{width:"80px",height:"30px",fontSize:"12px"}} onClick={() => handleStatusFilter(1)}>Vacant</Button>
            <Button variant="warning" style={{width:"80px",height:"30px",fontSize:"12px"}} onClick={() => handleStatusFilter(2)}>Booked</Button>
            <Button variant="danger" style={{width:"80px",height:"30px",fontSize:"12px"}} onClick={() => handleStatusFilter(3)}>Release</Button>
          </ButtonGroup>
          </div>
         
        </div>
        <div className="row">
        <div className="col-md-4">
          {facList.map((facility) => (
            <div key={facility.id_fac} style={{ display: "inline-block", marginRight: "12px" }}>
              <img style={{ width: "22px", marginRight: "8px" }} src={facility.fac_img_thumb} alt={facility.fac_name} />
              <Form.Check
                inline
                label={facility.fac_name}
                checked={facilityFilter.includes(facility.id_fac)}
                onChange={() => {
                  setFacilityFilter((prevFilters) =>
                    prevFilters.includes(facility.id_fac)
                      ? prevFilters.filter((id) => id !== facility.id_fac) // Remove if already selected
                      : [...prevFilters, facility.id_fac] // Add if not selected
                  );
                }}
              />
            </div>
          ))}
          </div>
        </div>


        <div className="calendar-container">
          {/* FullCalendar component */}
          <FullCalendar
            plugins={[dayGridPlugin,interactionPlugin]}
            initialView="dayGridMonth"
            selectable={true} // Enable date selection
            select={handleDateSelect} // Use select event for single date clicks
            dayMaxEventRows={true} // Enable "more" link for overflow events
            dayMaxEvents={2} // Limit to 6 events per day
            moreLinkText="See more" // Customize the "more" link text
            events={filteredSchList}
            eventClassNames={(eventInfo) => {
              switch (eventInfo.event.extendedProps.status) {
                case 1:
                  return "event-status-free";
                case 2:
                  return "event-status-booked";
                case 3:
                  return "event-status-past";
                default:
                  return "";
              }
            }}
            headerToolbar={{
              left: "",
              center: "title",
              right: "prev,next addScheduleButton",
            }}
            customButtons={{
              addScheduleButton: {
                text: "Calendar Schedule",
                click: handleAddNewSchedule,
              },
            }}
            height="480px"
            contentHeight="640px"
            eventClick={handleEventClick}
            
          />
        </div>
      </div>

    
      {/* Modal for adding a new schedule */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Form onSubmit={handleSaveSchedule}>
          <Modal.Header closeButton>
            <Modal.Title>Add Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Facilities</Form.Label>
                <Form.Select
                  value={scheduleData.facility}
                  onChange={(e) => setScheduleData({ ...scheduleData, facility: e.target.value })}
                  required
                >
                  <option value="">Choose...</option>
                  {facList.map((fac) => (
                    <option key={fac.id_fac} value={fac.id_fac}>
                      {fac.fac_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Date From</Form.Label>
                <Form.Control
                  type="date"
                  value={scheduleData.dateFrom}
                  onChange={(e) => setScheduleData({ ...scheduleData, dateFrom: e.target.value })}
                  required
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Date To</Form.Label>
                <Form.Control
                  type="date"
                  value={scheduleData.dateTo}
                  onChange={(e) => setScheduleData({ ...scheduleData, dateTo: e.target.value })}
                  required
                />
              </Form.Group>
            </Row>
            {scheduleData.timeSlots.map((slot, index) => (
              <Row className="mb-3" key={index}>
                <Form.Group as={Col}>
                  <Form.Label>Time From</Form.Label>
                  <Form.Control
                    type="time"
                    value={slot.timeFrom}
                    onChange={(e) => handleTimeSlotChange(index, "timeFrom", e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Time To</Form.Label>
                  <Form.Control
                    type="time"
                    value={slot.timeTo}
                    onChange={(e) => handleTimeSlotChange(index, "timeTo", e.target.value)}
                    required
                  />
                </Form.Group>
              </Row>
            ))}
            <Button size="sm" variant="light" onClick={handleAddMoreTime}>
              Add More Time
            </Button>
          </Modal.Body>
          <ModalFooter>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Loading..." : "Save Schedule"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Modal for adding booked participants */}
      <Modal show={showParticipantModal} onHide={handleCloseParticipantModal} size="lg">
        <Form
          onSubmit={selectedEvent?.extendedProps?.status === 1 ? handleSaveParticipant : handleUpdateParticipant}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedEvent && (
                <>
                  <div className="d-flex align-items-center mb-1">
                    <img
                      src={selectedEvent.extendedProps.imgfac}
                      alt="Facility"
                      className="img-fluid"
                      // style={{ width: "64px", height: "64px", objectFit: "cover" }}
                    />
                    <div className="ms-3">
                      <b style={{fontSize:"16px"}}>{selectedEvent.extendedProps.fac}</b>
                      {selectedEvent.extendedProps.status && (
                        <Badge
                          style={{fontSize:"12px", marginLeft:"10px"}}
                          bg={
                            selectedEvent.extendedProps.status === 1
                              ? "primary"
                              : selectedEvent.extendedProps.status === 2
                              ? "warning"
                              : "secondary"
                          }
                        >
                          {selectedEvent.extendedProps.status === 1
                            ? "Vacant"
                            : selectedEvent.extendedProps.status === 2
                            ? "Confirmed"
                            : "Completed"}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <div style={{fontSize:"14px"}}>
                      {selectedEvent.extendedProps.stime} - {selectedEvent.extendedProps.etime}
                    </div>
                    <div>
                      
                    </div>
                  </div>
                </>
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* Guest Field */}
            <Form.Group className="mb-3">
              <Form.Label>Guest</Form.Label>
              <Form.Control
                disabled={selectedEvent?.extendedProps?.status === 3}
                as="select"
                value={participantData.guestName}
                onChange={(e) => {
                  const selectedGuestName = e.target.value;
                  const selectedGuest = guestInHouse.find((guest) => guest.GuestName === selectedGuestName);
                  if (selectedGuest) {
                    setParticipantData({
                      ...participantData,
                      guestName: selectedGuest.GuestName,
                      roomNo: selectedGuest.roomNo,
                      roomName: selectedGuest.roomType,
                      arr: selectedGuest.ArrivalDate,
                      dep: selectedGuest.DepartDate,
                    });
                  } else {
                    setParticipantData({
                      ...participantData,
                      guestName: selectedGuestName,
                      roomNo: "",
                      roomName: "",
                      arr: "",
                      dep: "",
                    });
                  }
                }}
                required
              >
                <option value="" disabled>
                  Select Guest Name
                </option>
                {guestInHouse.map((guest, index) => (
                  <option key={index} value={guest.GuestName}>
                    {guest.roomNo} | {guest.GuestName}
                  </option>
                ))}
                <option value="other/complimentary">Other</option>
              </Form.Control>
            </Form.Group>

            {/* Pax Field */}
            <Form.Group className="mb-2">
              <Form.Label>Pax</Form.Label>
              <div>
                {[2, 3, 4, 5, 6].map((value) => (
                  <Form.Check
                    disabled={selectedEvent?.extendedProps?.status === 3}
                    key={value}
                    type="radio"
                    id={`pax-${value}`}
                    name="pax"
                    label={value}
                    value={value}
                    checked={participantData.pax == value}
                    style={{ marginRight: "1rem", fontSize: "1rem" }}
                    onChange={(e) => setParticipantData({ ...participantData, pax: parseInt(e.target.value, 10) })}
                    inline
                  />
                ))}
              </div>
            </Form.Group>

            {/* Room No and Dates */}
            {participantData.roomNo && (
              <Form.Group className="mb-0">
                <Form.Label style={{ fontWeight: "italic" }}>
                  Room No: {participantData.roomNo} - {participantData.roomName} | Arr-Dep:{" "}
                  {moment(participantData.arr).format("DD/MM/YYYY")} - {" "}
                  {moment(participantData.dep).format("DD/MM/YYYY")}
                </Form.Label>
              </Form.Group>
            )}

            {/* Remark Field */}
            <Form.Group className="mb-2">
              <Form.Label>Remark</Form.Label>
              <Editor
                disabled={selectedEvent?.extendedProps?.status === 3}
                apiKey="jzxha84y3xyify9uwj6ec47iceogua3cg0k4ftz1bfvxgbr5"
                init={{
                  directionality: "ltr",
                  toolbar:
                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                }}
                value={participantData.remark}
                onEditorChange={(content) => setParticipantData({ ...participantData, remark: content })}
              />
            </Form.Group>
          </Modal.Body>

          {/* Conditionally Render Insert or Update Button */}
          <ModalFooter>
            {selectedEvent?.extendedProps?.status === 1 && (
              <Button variant="primary" type="submit">
                Add Participant
              </Button>
            )}
            {selectedEvent?.extendedProps?.status === 2 && (
              <>
              <Button variant="warning" type="button" onClick={handleUpdateParticipant}>
                Change Data
              </Button>

              <Button variant="danger"  type="button" onClick={handleReleaseParticipant}>
                Release
              </Button>
              </>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      {/* Modal for detail date schedule */}
      <Modal show={showModalDetailDate} onHide={()=>setShowModalDetailDate(false)} size="lg">
        <Form >
          <Modal.Header closeButton>
            <Modal.Title>Date Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "2px" }}>
              {detailDate.length > 0 ? (
                detailDate.map((schedule) => (
                  <div key={schedule.id_sch} style={{ marginBottom: "12px" }}>
                    <Badge
                      bg={
                        schedule.schedule_status === 1
                          ? "primary"
                          : schedule.schedule_status === 2
                          ? "warning"
                          : "danger"
                      }
                      style={{
                        padding: "6px",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        marginRight: "8px",
                        marginBottom: "8px",
                      }}
                      onClick={() => handleEditClick(schedule)} // Click handler to open the form
                    >
                      <img
                        src={schedule.fac_img_thumb}
                        alt="facility icon"
                        style={{ width: "16px", height: "16px", marginRight: "4px" }}
                      />
                      {schedule.schedule_start} - {schedule.schedule_end}
                    </Badge>

                    {/* Conditional rendering of the form below the clicked badge */}
                    {editingSchedule === schedule.id_sch && (
                      <Form style={{ marginTop: "4px", display: "flex", alignItems: "center", gap: "8px" }}>
                      <Form.Group controlId={`startTime-${schedule.id_sch}`} style={{ marginBottom: 0 }}>
                        <Form.Label style={{ fontSize: "12px" }}>Start Time</Form.Label>
                        <Form.Control
                          type="time"
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                          style={{ width: "100px", padding: "4px" }}
                        />
                      </Form.Group>
                    
                      <Form.Group controlId={`endTime-${schedule.id_sch}`} style={{ marginBottom: 0 }}>
                        <Form.Label style={{ fontSize: "12px" }}>End Time</Form.Label>
                        <Form.Control
                          type="time"
                          value={endTime}
                          onChange={(e) => setEndTime(e.target.value)}
                          style={{ width: "100px", padding: "4px" }}
                        />
                      </Form.Group>
                    
                      <Button
                        variant="info"
                        onClick={() => handleSave(schedule.id_sch)}
                        style={{ fontSize: "12px", padding: "6px 12px" ,marginTop:"7%"}}
                      >
                        Update
                      </Button>
                    
                      {schedule.schedule_status === 1 && (
                        <Button
                          variant="danger"
                          onClick={() => handleDelete(schedule.id_sch,schedule.schedule_day)} // Add your delete function here
                          style={{ fontSize: "12px", padding: "6px 12px", marginTop: "7%" }}
                        >
                          Delete
                        </Button>
                      )}
                    </Form>
                    
                    )}
                  </div>
                ))
              ) : (
                <p>No schedules available for this date.</p>
              )}
            </div>
          </Modal.Body>
          <ModalFooter>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Loading..." : "Save Schedule"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Footer />
    </>
  );
};

export default Schedule;
