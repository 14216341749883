import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Login from "./pages/Login";

import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";

import NotFound from "./pages/NotFound";
import Facilities from "./pages/Fac";
import AddFacilities from "./pages/Fac/Add";
import DetailFacilities from "./pages/Fac/Detail";

import Schedules from  "./pages/Sch";
import Gallery from "./pages/Gallery";
import Configuration from "./pages/Con";
import Promotion from "./pages/Promo";

function App() {
  return (  
    <Router> 
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/config" component={Configuration} />
        <PrivateRoute exact path="/fac/list" component={Facilities} />
        <PrivateRoute exact path="/fac/add" component={AddFacilities} />
        <PrivateRoute exact path="/fac/detail/:id" component={DetailFacilities} />
        <PrivateRoute exact path="/sch/list" component={Schedules} />
        <PrivateRoute exact path="/gallery/list" component={Gallery} />
        <PrivateRoute exact path="/promotion/list" component={Promotion} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;


