import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table, Button, Form, Spinner, Modal,ButtonGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getUser } from '../../utils/Common';
import Header from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "../../utils/axios";
import moment from "moment";
import LOGO from "../../assets/logo.png";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom';

const Facilities = () => {
  
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataFac, setDataFac] = useState([]);
  const [userDetail, setUserDetail] = useState({});

  // Fetch facilities based on user hotel_id
  useEffect(() => {
    const fetchUserDataAndFacilities = async () => {
      const userData = getUser();
      if (userData) {
        setUserDetail(userData);
        console.log("User data:", userData);
        const idHotel = userData.hotel_id;

        try {
          const response = await axios.get(`/api/fac/hotel/${idHotel}`); // Ensure the correct endpoint is used
          console.log("Facilities data:", response.data);
          setDataFac(response.data);
        } catch (error) {
          if (error.response) {
            console.error(`Error: ${error.response.status} - ${error.response.statusText}`);
            if (error.response.status === 404) {
              console.error("The requested resource was not found.");
            }
          } else if (error.request) {
            console.error("No response received from the server.");
          } else {
            console.error("Error:", error.message);
          }
        }
      }
    };

    fetchUserDataAndFacilities();
  }, []); // Empty dependency array means this runs once when the component mounts

  return (
    <>
      <Header userDetail={userDetail} />
      <div className="container-fluid mt-5">
        <div className="container pl-5 pr-5">

        <ButtonGroup className="pb-2" size="sm" onClick={()=>history.push('/fac/add')}>
          <Button><i class="bi bi-calendar-plus"></i> New</Button>
        </ButtonGroup>


          {/* Example of displaying facilities data */}
          {loading ? (
            <Spinner animation="border" />
          ) : (

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Facility </th>
                  <th>Description</th>
                  <th><i class="bi bi-sliders"></i></th>
                </tr>
              </thead>
              <tbody>
                {dataFac.map((facility, index) => (
                  <tr key={facility.id_fac}>
                    <td>{index + 1}</td>
                    <td>{facility.fac_name}</td>
                    <td>{facility.fac_desc}</td>
                    <td> <Button  onClick={()=>history.push(`/fac/detail/${facility.id_fac}`)} size="sm" variant="info"><i class="bi bi-pencil-square"></i></Button></td>
                  </tr>
                ))}
              </tbody>
            </Table>

          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Facilities;
