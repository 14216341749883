import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import axios from "axios";
function Footer() {


  const [ip, setIP] = useState('Fetching IP...');
  const [ipPublic, setIPPublic] = useState('Render IP...');
  const [error, setError] = useState(false); // Track if an error occurs

  useEffect(() => {
    const getIPPublic = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIPPublic(res.data.ip);
  };

   // Function to get local IP address
   const getLocalIP = () => {
    const RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    if (!RTCPeerConnection) {
      setIP('WebRTC not supported by your browser');
      return;
    }

    const peerConnection = new RTCPeerConnection();
    peerConnection.createDataChannel(""); // Create a bogus data channel
    peerConnection.createOffer().then((offer) => peerConnection.setLocalDescription(offer));

    const ipTimeout = setTimeout(() => {
      setIP('Failed to fetch IP address. Check your browser settings.');
      setError(true);  // Trigger an error state if the IP cannot be fetched within the timeout
    }, 5000); // Set timeout to 5 seconds

    peerConnection.onicecandidate = (event) => {
      if (event && event.candidate && event.candidate.candidate) {
        const ipRegex = /([0-9]{1,3}\.){3}[0-9]{1,3}/; // Regex to match IPv4 addresses
        const match = ipRegex.exec(event.candidate.candidate); // Try to find an IP address

        if (match) {
          const localIP = match[0];
          setIP(localIP); // Set the local IP in state
          peerConnection.onicecandidate = null; // Stop listening for ICE candidates once IP is found
          clearTimeout(ipTimeout); // Clear the timeout when the IP is found
        }
      }
    };
  };

  getLocalIP(); // Call the function when component mounts
  getIPPublic();
  }, [ip]); // Ensure it only runs once, like componentDidMount

  return (
    <footer
      style={{ fontSize: "12px" }}
      className="fixed-bottom bg-dark text-light py-3"
    >
      <Container>
        <Row>
          <Col className="marquee">
            <span>
              {ip} - {ipPublic}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p className="mb-0">
              &copy; 2024 Ashley Hotel Group. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
