import React, { useState, useEffect } from "react";
import { Col, Row, Form, Card, CardBody, Button, CardFooter } from "react-bootstrap";
import { getUser } from '../../utils/Common';
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const Add = () => {
  const history = useHistory();
  const [userDetail, setUserDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    facName: "",
    description: "",
    thumbnail: null,
    slide1: null,
    slide2: null,
    slide3: null,
    slide4: null,
    slide5: null,
  });

  const [previewImages, setPreviewImages] = useState({
    thumbnail: null,
    slide1: null,
    slide2: null,
    slide3: null,
    slide4: null,
    slide5: null,
  });

  // Function to handle image upload and preview
  const handleFileChange = (e, type) => {
    const file = e.target.files[0];

    if (file) {
      if (type === "thumbnail") {
        setFormData({ ...formData, thumbnail: file });
        setPreviewImages({ ...previewImages, thumbnail: URL.createObjectURL(file) });
      } else {
        setFormData({ ...formData, [type]: file });
        setPreviewImages({ ...previewImages, [type]: URL.createObjectURL(file) });
      }
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    // If form is invalid, prevent submission
    if (form.checkValidity() === false) {
        e.stopPropagation();
        return;
    }

    setLoading(true);

    const uploadData = new FormData();
    uploadData.append("facName", formData.facName);
    uploadData.append("description", formData.description);

    if (formData.thumbnail) uploadData.append("thumbnail", formData.thumbnail);
    if (formData.slide1) uploadData.append("slide1", formData.slide1);
    if (formData.slide2) uploadData.append("slide2", formData.slide2);
    if (formData.slide3) uploadData.append("slide3", formData.slide3);
    if (formData.slide4) uploadData.append("slide4", formData.slide4);
    if (formData.slide5) uploadData.append("slide5", formData.slide5);

    // Log the form data to ensure files are appended correctly
    // for (let pair of uploadData.entries()) {
    //     console.log(pair[0] + ':', pair[1]);
    // }

    try {
        // Remove manual headers setting; Axios handles boundary correctly
        const response = await axios.post(`/api/fac/add/${userDetail.hotel_id}`, uploadData);
        // console.log(response.data);
        if(response.data.status === 200){
            Swal.fire("Success", response.data.message, "success");
            history.push(`/fac/detail/${response.data.facility_id}`);
        } else {
            Swal.fire("Info", response.data.message, "info");
        }
        
    } catch (error) {
        console.error("Error:", error.response?.data || error.message);
        Swal.fire("Error", "Failed to add facility", "error");
    } finally {
        setLoading(false);
    }
    };


  useEffect(() => {
    const userData = getUser();
    if (userData) {
      setUserDetail(userData);
      console.log("User: ", userData);
    }
  }, [getUser]);

  return (
    <>
      <Header />
      <div className="container-fluid mt-5" style={{ marginBottom: "100px" }}>
        <div className="container-fluid">
          <Card>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <CardBody>
                <Form.Group className="mb-3">
                  <Form.Label>Facility </Form.Label>
                  <Form.Control
                    required
                    size="sm"
                    type="text"
                    value={formData.facName}
                    onChange={(e) => setFormData({ ...formData, facName: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Facility Name is required.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Editor
                    apiKey="jzxha84y3xyify9uwj6ec47iceogua3cg0k4ftz1bfvxgbr5"
                    init={{
                      directionality: "ltr",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    }}
                    onEditorChange={(content) =>
                      setFormData({ ...formData, description: content })
                    }
                  />
                  {validated && !formData.description && (
                    <div className="invalid-feedback d-block">Description is required.</div>
                  )}
                </Form.Group>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    {previewImages.thumbnail && (
                      <img
                        src={previewImages.thumbnail}
                        alt="Thumbnail preview"
                        className="img-thumbnail mb-2"
                        width={100}
                        height={100}
                      />
                    )}
                    <Form.Control
                      required
                      type="file"
                      onChange={(e) => handleFileChange(e, "thumbnail")}
                    />
                    <Form.Label className="pt-2">Thumbnail</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Thumbnail is required.
                    </Form.Control.Feedback>
                  </Form.Group>

                  {[1, 2, 3, 4, 5].map((index) => (
                    <Form.Group as={Col} key={index}>
                      {previewImages[`slide${index}`] && (
                        <img
                          src={previewImages[`slide${index}`]}
                          alt={`Slide ${index} preview`}
                          className="img-thumbnail mb-2"
                          width={100}
                          height={100}
                        />
                      )}
                      <Form.Control
                        required={index === 1} // Make at least the first image required
                        type="file"
                        onChange={(e) => handleFileChange(e, `slide${index}`)}
                      />
                      <Form.Label className="pt-1">{`Slide ${index}`}</Form.Label>
                      {index === 1 && (
                        <Form.Control.Feedback type="invalid">
                          At least one slide image is required.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  ))}
                </Row>
              </CardBody>
              <CardFooter>
                <div className="d-flex justify-content-end">
                  <Button type="submit" variant="primary" disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                </div>
              </CardFooter>
            </Form>
          </Card>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Add;
