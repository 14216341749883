import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table, Button, Form, Spinner, Modal, Card, CardBody } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashboard.css";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "../utils/axios";
import moment from "moment";
import LOGO from "../assets/logo.png";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  const pdfContentRef = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const cardData = [
    { title: 'Total Visitor', amount: '-', saved: 'Saved 25%', bgColor: 'primary' },
    { title: 'Visitor Today', amount: '-', saved: 'Saved 25%', bgColor: 'info' },
    { title: 'Booking Today', amount: '-', saved: 'Saved 25%', bgColor: 'warning' }, // Custom color
    { title: 'Net Profit Margin', amount: '-', saved: 'Saved 65%', bgColor: 'success' },
  ];

  useEffect(() => {

  }, []);

  return (
    <>
      <Header />
        <div className="container-fluid mt-5">
          
          <Row>
            {cardData.map((card, index) => (
              <Col key={index} sm={12} md={3} lg={3} className="mb-4">
                <Card className={`bg-${card.bgColor} text-white`}>
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                    <h4>{card.amount}</h4>
                    <p>{card.saved}</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
         
        </div>
      <Footer />

    
    </>
  );

};

export default Dashboard;
