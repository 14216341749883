import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table, Button, Form,ButtonGroup, Spinner, Modal,Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashboard.css";
import { getUser } from "../../utils/Common";
import Header from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "../../utils/axios";
import moment from "moment";
import LOGO from "../../assets/logo.png";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom';

const Gallery = () => {
  const [hotel, setHotelID] = useState("");
  const [userDetail, setUserDetail] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [modalView, setModalView] = useState(false);
  const [detPhoto, setDetPhoto] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [modalAddPhotos, setModalAddPhotos] = useState(false);

  const getListPhotos = async() => {
    setLoading(true)

    try {
      const response = await axios.get(`/api/list/photos/${hotel}`);
      console.log(response.data);
      
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      // Swal.fire("Error", "Failed to get guest in house", "error");
    } finally {
      setLoading(false);
      // handleCloseParticipantModal();
      // Swal.fire("Success", "Participant has been saved", "success");
    }


  }

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const doUpload = async () => {

    if (!selectedFiles) {
      alert('Please select files to upload');
      return;
    }

    const formData = new FormData();

    // Append each selected file to the form data as part of an array
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files[]', selectedFiles[i]); // Use 'files[]' to ensure it's treated as an array
    }
    setLoading(true)
    try {
      const response = await axios.post(`/api/upload/to-gallery/${hotel}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      console.log('Upload success:', response.data);
      alert('Files uploaded successfully!');
      setModalAddPhotos(false)
      setLoading(false)
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Error uploading files');
      
      setModalAddPhotos(false)
    }
  };

  const showPhoto = (photo)=> {
    // alert(photo)
    setDetPhoto(photo)
    console.log(photo)
    if(photo){
      setModalView(true)
    }
  }

  const doUpdate = async () => {
    try {
      const response = await axios.post(`/api/photo/gallery/update/${detPhoto.id_photo}`, {
        description: detPhoto.des_photo, // Send the updated description
      });

      console.log(response.data)
      
      if (response.status === 200) {
        alert("Photo description updated successfully!");
        // Optionally, you can refresh the photo gallery or close the modal
        setModalView(false);
        // Call any function to refresh the gallery
      }
    } catch (error) {
      console.error("Error updating photo:", error.response?.data || error.message);
      alert("Failed to update photo description.");
    }
  };
  
  const doDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this photo?")) {
      return;
    }
  
    try {
      const response = await axios.get(`/api/photo/gallery/delete/${detPhoto.id_photo}`);
      
      if (response.status === 200) {
        alert("Photo deleted successfully!");
        // Optionally, you can refresh the photo gallery or close the modal
        setModalView(false);
        window.location.reload()
        // Call any function to refresh the gallery
      }
    } catch (error) {
      console.error("Error deleting photo:", error.response?.data || error.message);
      alert("Failed to delete the photo.");
    }
  };
  


  useEffect(() => {
    const fetchPhotos = async () => {
      const userData = getUser();
      if (userData) {
        setUserDetail(userData);
        const idHotel = userData.hotel_id;
        setHotelID(userData.hotel_id);
        setLoading(true)

        try {
          const response = await axios.get(`/api/list/photos/${idHotel}`);
          console.log(response.data);
          if(response.data.status === 200){
            setGallery(response.data.data)
            console.log(response.data.data)
          }
        } catch (error) {
          console.error("Error:", error.response?.data || error.message);
          // Swal.fire("Error", "Failed to get guest in house", "error");
        } finally {
          setLoading(false);
          // handleCloseParticipantModal();
          // Swal.fire("Success", "Participant has been saved", "success");
        }

       
      }
    };
    fetchPhotos();
  }, []);


  return (
    <>
      <Header />
        <div className="container-fluid mt-5 mb-5">
          <div className="container pl-5 pr-5 mb-5">

            <Row>
              <Col md={12}>

                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Photo Gallery</h5>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="secondary" onClick={()=>setModalAddPhotos(true)}><i class="bi bi-images"></i></Button>
                    <Button variant="secondary" onClick={()=>window.location.reload()}><i class="bi bi-arrow-clockwise"></i></Button>
                  </ButtonGroup>
                </div>
                <hr/>
              </Col>
            </Row>
            
            <Row>
              {gallery.length === 0 ? (
                <Col className="text-center" >
                  <h5 style={{ marginTop: '20px', color: 'gray' }}>Empty Galleries</h5> {/* Centered message */}
                </Col>
              ) : (
                gallery.map((photo, index) => (
                  <Col className="mb-2"  key={index} md={2} sm={6} lg={2}>
                    <Card style={{ maxWidth: '16rem' ,maxHeight:"15rem"}} onClick={()=>showPhoto(photo)}>
                      <Card.Img
                        style={{ maxHeight: "180px", width: "auto" }}
                        variant="top"
                        src={photo.url_photo} // Dynamically load image URL
                        alt={`Photo ${index + 1}`}
                      />
                      <Card.Body>
                      <Card.Text className="text-truncate-2">
                        {photo.des_photo || 'Default description'} {/* Display description if available */}
                      </Card.Text>
                       
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              )}
            </Row>

          </div>
        </div>
      <Footer />

      <Modal  show={modalAddPhotos} onHide={()=>setModalAddPhotos(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add photos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Upload Images</Form.Label>
            <Form.Control
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModalAddPhotos(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={doUpload} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Upload'}
        </Button>
      </Modal.Footer>
      </Modal>

      {/* View Image  */}
      <Modal size="lg" show={modalView} onHide={() => setModalView(false)}>
     
      <Modal.Body>
        <img className="img-fluid" src={detPhoto.url_photo} alt={`ashley-hotel-group-${detPhoto.id_photo}`} />
        {/* Form for updating the description */}
        <Form>
        <Form.Group className="mt-2 mb-3" controlId="formPhotoDescription">
          <Form.Label>Photo Description</Form.Label>
          <Form.Control 
            as="textarea"  // Change to textarea
            rows={3}       // Number of rows (height of textarea)
            placeholder="Enter description" 
            value={detPhoto.des_photo || ''}  // Controlled input
            onChange={(e) => setDetPhoto({ ...detPhoto, des_photo: e.target.value })}  // Update description
          />
        </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn btn-sm" variant="secondary" onClick={() => setModalView(false)}>
          Close
        </Button>
        <Button className="btn btn-sm" variant="primary" onClick={doUpdate}>
          Update
        </Button>
        <Button className="btn btn-sm" variant="danger" onClick={doDelete}>
          Delete
        </Button>
      </Modal.Footer>
      </Modal>

    </>
  );

};

export default Gallery;
