import React, { useState, useEffect } from "react";
import { Col, Row, Card, Form, Button, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashboard.css";
import { getUser } from "../../utils/Common";
import Header from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "../../utils/axios";
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom';
import QRCode from "react-qr-code";

const Configuration = () => {
  const [userDetail, setUserDetail] = useState({});
  const [hotel, setHotelID] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [fileLogo, setFileLogo] = useState(null); // To store selected file
  const [previewLogo, setPreviewLogo] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState({});

  const [hotelDetail, setHotelDetail] = useState({
    nm_hotel: "",
    slug: "",
    col_1: "#000000",
    col_2: "#000000",
    alamat: "",
    email: "",
    phone: "",
    maps: "",
    desc_hotel: "",
  });

  const [previews, setPreviews] = useState({});
  // List of image keys to loop through
  const imageKeys = ['img1', 'img2', 'img3', 'img4', 'img5', 'img6', 'img7', 'img8'];

  const handleFileImgChange = (e, key) => {
    const file = e.target.files[0];

    // Validate file type (JPEG, PNG)
    if (file && !file.type.match('image/jpeg') && !file.type.match('image/png')) {
      Swal.fire("Invalid File Type", "Please upload a JPEG or PNG image.", "error");
      return;
    }

    // Validate file size (example: limit to 2MB)
    if (file && file.size > 2 * 1024 * 1024) {
      Swal.fire("File too large", "Please upload a file smaller than 2MB.", "error");
      return;
    }

    // Set the selected file for the specific key
    setSelectedFiles((prevState) => ({
      ...prevState,
      [key]: file,
    }));

    // Generate image preview URL and store it
    const previewUrl = URL.createObjectURL(file);
    setPreviews((prevState) => ({
      ...prevState,
      [key]: previewUrl,
    }));
  };

  // Handle image update
  const handleImageUpdate = async (key) => {
    if (!selectedFiles[key]) {
      Swal.fire("No File Selected", `Please choose an image file for ${key} before updating.`, "error");
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFiles[key]);

    try {
      setLoading((prevState) => ({
        ...prevState,
        [key]: true, // Set loading for the specific image being updated
      }));

      // Make the POST request to upload the image
      const response = await axios.post(`/api/update/slide-image/${hotel}/${key}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data)

      if (response.data.status === 200) {
        Swal.fire("Success", `Image Slide ${key} updated successfully!`, "success");
        // Optionally, you can update the UI or refetch the data here
      } else {
        Swal.fire("Error", response.data.message || `Failed to update the image ${key}.`, "error");
      }
    } catch (error) {
      console.error(`Error updating image ${key}:`, error);
      Swal.fire("Error", `Failed to update image ${key}.`, "error");
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        [key]: false, // Stop loading for the specific image
      }));
    }
  };

  const handleFileLogoChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileLogo(selectedFile);
    // Generate preview using FileReader
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewLogo(reader.result); // Set preview to the loaded image
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile); // Read the file as data URL for preview
    } else {
      setPreviewLogo(hotelDetail.img_logo); // If no file selected, keep original logo
    }
  };

  const handleLogoUpload = async () => {
    if (!fileLogo) {
      Swal.fire("Error", "Please select a file to upload", "error");
      return;
    }

    const formData = new FormData();
    formData.append("logo", fileLogo); // Assuming the server expects 'logo' as the field name

    setLoading(true);
    try {
      const response = await axios.post(`/api/upload-logo/${hotel}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.status === 200) {
        // Update the logo URL in the state
        setHotelDetail({
          ...hotelDetail,
          img_logo: response.data.logo_url, // Assuming the response contains 'logo_url'
        });
        Swal.fire("Success", "Logo updated successfully", "success");
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      Swal.fire("Error", "Failed to upload logo", "error");
    } finally {
      setLoading(false);
    }
  };

  const getProfile = async (idHotel) => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/profile/${idHotel}`);
      if (response.data.status === 200) {
        console.log(response.data.data)
        setHotelDetail(response.data.data);
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      Swal.fire("Error", "Failed to get profile", "error");
    } finally {
      setLoading(false);
    }
  };

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the input name is "nm_hotel", we want to also update the "slug"
  if (name === 'nm_hotel') {
    setHotelDetail((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      slug: generateSlug(value), // Automatically generate the slug
    }));
  } else {
    setHotelDetail((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  }
  };

  // Function to convert a string to a slug format
  const generateSlug = (value) => {
    return value
      .toLowerCase()           // Convert to lowercase
      .replace(/ /g, '-')      // Replace spaces with hyphens
      .replace(/[^\w-]+/g, ''); // Remove any non-alphanumeric characters except hyphens
  };

  const handleEditorChange = (content) => {
    setHotelDetail({
      ...hotelDetail,
      desc_hotel: content,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!hotelDetail.nm_hotel) newErrors.nm_hotel = "Property name is required";
    if (!hotelDetail.slug) newErrors.slug = "Slug is required";
    if (!hotelDetail.email) newErrors.email = "Email is required";
    if (!hotelDetail.phone) newErrors.phone = "Phone number is required";
    if (!hotelDetail.alamat) newErrors.alamat = "Address is required";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      Swal.fire("Validation Error", "Please fix the validation errors.", "error");
      return;
    }
    setLoading(true);
    // console.log(hotelDetail)
    try {
      const response = await axios.post(`/api/update/property/${hotel}`, hotelDetail);
      console.log(response.data)
      if (response.data.status === 200) {
        Swal.fire("Success", response.data.message, "success");
      }
    } catch (error) {
      console.error("Error:",error);
      Swal.fire("Error", "Failed to update profile", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userData = getUser();
    if (userData) {
      const idHotel = userData.hotel_id;
      setUserDetail(userData);
      setHotelID(idHotel);

      if (idHotel) {
        getProfile(idHotel);
      }
    }
  }, []);

  return (
    <>
      <Header />
      <div className="container mt-3">
        <Row className="mb-5">
          <Col className="mb-5">
            <Card>
              <Card.Header>Configuration</Card.Header>
              <Card.Body>
                <Card.Title>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Hotel Logo */}
                    <div className="text-justify">
                      <img src={previewLogo || hotelDetail.img_logo} alt="Hotel Logo" style={{ width: "150px",marginLeft:"20px", marginBottom: "10px" }} />
                      <Form.Control 
                        type="file"
                        onChange={handleFileLogoChange}
                      />
                      <Button size="sm" className="mt-2" variant="primary" onClick={handleLogoUpload} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : "Upload"}
                      </Button>
                    </div>
                    {/* QR Code */}
                    <div style={{ height: "auto", maxWidth: 120, width: "100%" }}>
                      <QRCode
                        size={300}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`http://172.70.64.206:3002/welcome/${hotelDetail.slug}`}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                  </div>
                </Card.Title>
                <Card.Text>
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-2">
                    <Form.Group as={Col} lg={5} md={5}>
                        <Form.Label>Property</Form.Label>
                        <Form.Control
                            type="text"
                            name="nm_hotel"
                            value={hotelDetail.nm_hotel}
                            onChange={handleInputChange}
                            isInvalid={!!errors.nm_hotel}
                        />
                        <Form.Control.Feedback type="invalid">{errors.nm_hotel}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} lg={7} md={7}>
                        <Form.Label>Slug</Form.Label>
                        <Form.Control
                            type="text"
                            name="slug"
                            value={hotelDetail.slug}
                            onChange={handleInputChange}
                            isInvalid={!!errors.slug}
                        />
                        <Form.Control.Feedback type="invalid">{errors.slug}</Form.Control.Feedback>
                    </Form.Group>
                    </Row>

                    <Row className="mb-2">
                      <Form.Group as={Col} lg={1} md={1}>
                        <Form.Label>Color 1</Form.Label>
                        <Form.Control 
                          type="color" 
                          name="col_1"
                          value={hotelDetail.col_1} 
                          onChange={handleInputChange}
                        />
                      </Form.Group>

                      <Form.Group as={Col} lg={1} md={1}>
                        <Form.Label>Color 2</Form.Label>
                        <Form.Control 
                          type="color" 
                          name="col_2"
                          value={hotelDetail.col_2} 
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Row>

                    <Form.Group className="mb-3">
                      <Form.Label>Address</Form.Label>
                      <Form.Control 
                        as="textarea" 
                        rows={3} 
                        name="alamat"
                        value={hotelDetail.alamat}
                        onChange={handleInputChange}
                        isInvalid={!!errors.alamat}
                      />
                      <Form.Control.Feedback type="invalid">{errors.alamat}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control 
                        type="email" 
                        name="email"
                        value={hotelDetail.email} 
                        onChange={handleInputChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control 
                        type="text" 
                        name="phone"
                        value={hotelDetail.phone} 
                        onChange={handleInputChange}
                        isInvalid={!!errors.phone}
                      />
                      <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Maps</Form.Label>
                      <Form.Control 
                        as="textarea" 
                        rows={5} 
                        name="maps"
                        value={hotelDetail.maps} 
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Editor
                        apiKey="jzxha84y3xyify9uwj6ec47iceogua3cg0k4ftz1bfvxgbr5"
                        value={hotelDetail.desc_hotel}
                        onEditorChange={handleEditorChange}
                        init={{
                          directionality: "ltr",
                          toolbar:
                            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                        }}
                      />
                    </Form.Group>

                     {/* For Image Slide */}
                     <Row className="mb-2 container">
                        <Col>
                            *Slider Review
                        </Col>
                     </Row>

                     <Row className="mb-3 text-justify">
                        {imageKeys.map((key, index) => (
                            <Col key={index}>
                            <img style={{width:"500px",height:"300px"}} className="mb-2" src={previews[key] || hotelDetail[key] || "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"} alt={`Hotel ${key}`} />
                            <div>
                                <Form.Control
                                type="file"
                                size="sm"
                                className="mb-2"
                                onChange={(e) => handleFileImgChange(e, key)}
                                />
                            </div>
                            <div>
                                <Button
                                variant="outline-info"
                                size="sm"
                                style={{ fontSize: "12px",marginBottom:"10px" }}
                                onClick={() => handleImageUpdate(key)}
                                disabled={loading[key]}
                                >
                                {loading[key] ? "Updating..." : "Update"}
                                </Button>
                            </div>
                            </Col>
                        ))}
                    </Row>

                    <hr/>
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? <Spinner animation="border" size="sm" /> : "Update"}
                    </Button>
                  </Form>

                   


                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default Configuration;
