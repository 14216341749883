import React, { useState, useEffect } from "react";
import { Col, Row, Form, Card, CardBody, Button, CardFooter } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getUser } from "../../utils/Common";
import Header from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const Detail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    facName: "",
    description: "",
    thumbnail: null,
    slide1: null,
    slide2: null,
    slide3: null,
    slide4: null,
    slide5: null,
  });
  const [previewImages, setPreviewImages] = useState({
    thumbnail: "",
    slide1: "",
    slide2: "",
    slide3: "",
    slide4: "",
    slide5: "",
  });

  useEffect(() => {
    const fetchDetailFacilities = async () => {
      try {
        const response = await axios.get(`/api/fac/detail/${id}`);
        const facilityData = response.data.facility;

        // Set the initial form data and preview images
        setFormData({
          facName: facilityData.fac_name,
          description: facilityData.fac_desc,
          thumbnail: null,
          slide1: null,
          slide2: null,
          slide3: null,
          slide4: null,
          slide5: null,
        });

        setPreviewImages({
          thumbnail: facilityData.fac_img_thumb,
          slide1: facilityData.fac_img1,
          slide2: facilityData.fac_img2,
          slide3: facilityData.fac_img3,
          slide4: facilityData.fac_img4,
          slide5: facilityData.fac_img5,
        });
      } catch (error) {
        console.error("Error fetching facility details:", error);
      }
    };

    fetchDetailFacilities();
  }, [id]);

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, [type]: file });
      setPreviewImages({ ...previewImages, [type]: URL.createObjectURL(file) });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    setLoading(true);

    const uploadData = new FormData();
    uploadData.append("facName", formData.facName);
    uploadData.append("description", formData.description);

    if (formData.thumbnail) {
      uploadData.append("thumbnail", formData.thumbnail);
    }

    for (let i = 1; i <= 5; i++) {
      const slideField = `slide${i}`;
      if (formData[slideField]) {
        uploadData.append(slideField, formData[slideField]);
      }
    }

    try {
      await axios.post(`/api/fac/update/${id}`, uploadData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      Swal.fire("Success", "Facility updated successfully", "success");
      history.push(`/fac/detail/${id}`);
    } catch (error) {
      Swal.fire("Error", "Failed to update facility", "error");
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/api/fac/delete/${id}`);
          Swal.fire('Deleted!', 'Facility has been deleted.', 'success');
          history.push("/fac/list");
        } catch (error) {
          Swal.fire('Error!', 'Failed to delete facility.', 'error');
        }
      }
    });
  };

  return (
    <>
      <Header />
      <div className="container-fluid mt-5" style={{ marginBottom: "100px" }}>
        <div className="container-fluid">
          <Card>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <CardBody>
                <Form.Group className="mb-3">
                  <Form.Label>Facility Name</Form.Label>
                  <Form.Control
                    required
                    size="sm"
                    type="text"
                    value={formData.facName}
                    onChange={(e) => setFormData({ ...formData, facName: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Facility Name is required.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Editor
                    apiKey="jzxha84y3xyify9uwj6ec47iceogua3cg0k4ftz1bfvxgbr5"
                    value={formData.description}
                    init={{
                      directionality: "ltr",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    }}
                    onEditorChange={(content) =>
                      setFormData({ ...formData, description: content })
                    }
                  />
                  {validated && !formData.description && (
                    <div className="invalid-feedback d-block">Description is required.</div>
                  )}
                </Form.Group>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    {previewImages.thumbnail && (
                      <img
                        src={previewImages.thumbnail}
                        alt="Thumbnail preview"
                        className="img-thumbnail mb-2"
                        width={100}
                        height={100}
                      />
                    )}
                    <Form.Control
                      type="file"
                      onChange={(e) => handleFileChange(e, "thumbnail")}
                    />
                    <Form.Label className="pt-2">Thumbnail</Form.Label>
                  </Form.Group>

                  {[1, 2, 3, 4, 5].map((index) => (
                    <Form.Group as={Col} key={index}>
                      {previewImages[`slide${index}`] && (
                        <img
                          src={previewImages[`slide${index}`]}
                          alt={`Slide ${index} preview`}
                          className="img-thumbnail mb-2"
                          width={100}
                          height={100}
                        />
                      )}
                      <Form.Control
                        type="file"
                        onChange={(e) => handleFileChange(e, `slide${index}`)}
                      />
                      <Form.Label className="pt-1">{`Slide ${index}`}</Form.Label>
                    </Form.Group>
                  ))}
                </Row>
              </CardBody>
              <CardFooter>
                <div className="d-flex justify-content-end">
                  <Button
                    style={{ marginRight: "12px" }}
                    variant="danger"
                    onClick={() => confirmDelete()}
                  >
                    Delete
                  </Button>
                  <Button type="submit" variant="primary" disabled={loading}>
                    {loading ? "Loading..." : "Update"}
                  </Button>
                </div>
              </CardFooter>
            </Form>
          </Card>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Detail;
