import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table, Button, Form, Spinner, Modal, Card, CardBody, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashboard.css";
import { getUser } from "../../utils/Common";
import Header from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "../../utils/axios";
import moment from "moment";
import LOGO from "../../assets/logo.png";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom';

const Promotion = () => {
  const pdfContentRef = useRef(null);
  const history = useHistory();
  const [userDetail, setUserDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);
 

  useEffect(() => {
    const userData = getUser();
    if (userData) {
      setUserDetail(userData);
      const idHotel = userData.hotel_id;
      setLoading(true);

      const fetchPromotions = async () => {
        try {
          const response = await axios.get(`/api/list/promotion/${idHotel}`);
          
          if (response.data.status === 200) {
            // Handle success, e.g., update state with promotions data
            setPromotions(response.data.data);
            // setGallery(response.data.data);  // or whatever state you want to update
          }
        } catch (error) {
          console.error("Error:", error.response?.data || error.message);
          // Optionally show an alert or message to the user
        } finally {
          setLoading(false);
        }
      };

      fetchPromotions(); // Call the async function
    }

  }, []); // Ensure it only runs once, like componentDidMount

  return (
    <>
      <Header />
      <div>
      {loading ? (
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <p style={{ fontSize: '24px' }}>Loading...</p>
        </div>// Show loading spinner or message while fetching data
      ) : (
        <div className="container-fluid mt-5">
            <Container>
                <Row>
                    {/* Render the table with promotions data */}
                    {promotions.length > 0 ? (
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Thumbnail</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Created Date</th>
                           
                        </tr>
                        </thead>
                        <tbody>
                        {promotions.map((promo, index) => (
                            <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{promo.img_title}</td>
                            <td>{promo.title}</td>
                            <td>{promo.desc_promo}</td>
                            <td>{promo.start}</td>
                            <td>{promo.end}</td>
                            <td>{promo.created_at}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <p>No promotions available for this hotel.</p>
                        </div>
                    )}
                </Row>
            </Container>
        </div>
      )}
    </div>
   
    <Footer />

    
    </>
  );

};

export default Promotion;
